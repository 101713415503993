<template>
  <div class="v4-page-list-2-container v4-page-project-container-use">
    <div class="list2-box-container">
      <template v-if="$bowserMobile && currentChildTypes.length > 0">
        <div class="projects-category-container">
          <div class="category__inner">
            <router-link
              class="category__link"
              :class="{
                actived: typeid === link.id
              }"
              v-for="(link, index) in currentChildTypes"
              :key="index"
              :to="{
                path: `/projects/${$route.params.typeName}/${link.parent_id}/${link.id}`
              }"
            >
              <p class="en">
                {{ link.name.en }}
                <template v-if="link.id === typeid"> | {{ link.name.cn }} </template>
              </p>
              <p class="cn">{{ link.name.cn }}</p>
            </router-link>
          </div>
        </div>
      </template>
      <div
        class="box__wrapper"
        v-infinite-scroll="getLists"
        :infinite-scroll-disabled="disabled"
        :infinite-scroll-immediate="false"
        v-if="projects.length > 0"
      >
        <div class="list-box__item-row" v-for="(row, index) in projects" :key="index">
          <div
            class="list-box__item scrollObj"
            v-for="(shape, si) in shapeMap.lists[index % shapeLength]"
            :key="si"
            :class="[shape === shapeMap.small ? 'mobile-small' : 'mobile-big']"
          >
            <template
              v-if="
                shape !== shapeMap.placeholder &&
                  row[
                    selectIndex(
                      shapeMap.lists[index % shapeLength].findIndex(
                        item => item === shapeMap.placeholder
                      ),
                      si
                    )
                  ]
              "
            >
              <div
                class="item__wrapper"
                @click="
                  onJumpDetail(
                    row[
                      selectIndex(
                        shapeMap.lists[index % shapeLength].findIndex(
                          item => item === shapeMap.placeholder
                        ),
                        si
                      )
                    ].id
                  )
                "
              >
                <div
                  class="item__cover"
                  :style="{
                    backgroundColor: randomBg()
                  }"
                >
                  <img
                    class="lazyload"
                    :data-shape="shape === shapeMap.small ? 'sm' : 'big'"
                    :data-src="
                      row[
                        selectIndex(
                          shapeMap.lists[index % shapeLength].findIndex(
                            item => item === shapeMap.placeholder
                          ),
                          si
                        )
                      ].cover
                    "
                    alt=""
                  />
                </div>
                <div class="item__info">
                  <p>
                    {{
                      row[
                        selectIndex(
                          shapeMap.lists[index % shapeLength].findIndex(
                            item => item === shapeMap.placeholder
                          ),
                          si
                        )
                      ].name.en
                    }}
                  </p>
                  <p>
                    {{
                      row[
                        selectIndex(
                          shapeMap.lists[index % shapeLength].findIndex(
                            item => item === shapeMap.placeholder
                          ),
                          si
                        )
                      ].name.cn
                    }}
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="list-2-fixed-title scrollObj" data-extend="delay">
      <p class="nmb">{{ $store.state.currentSubType }}</p>
    </div>
    <page-footer></page-footer>
    <router-link tag="div" class="fixed-back-container" to="/type">
      <div class="dot-container">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </div>
      <div class="text-container">back</div>
    </router-link>
  </div>
</template>

<script>
import { demo2OnScroll } from '@/assets/utils/common';
import bowser from 'bowser';
import { mapState } from 'vuex';
import pageFooter from '../common/page-footer.vue';

const small = 0;
const big = 1;
const placeholder = 2;
const typeFormat = bowser.mobile
  ? [
    [big, big],
    [big, big],
    [big, big],
  ]
  : [
    [small, big, placeholder, big],
    [big, placeholder, big, small],
    [placeholder, small, big, big],
    [big, placeholder, big, small],
    [big, big, small, placeholder],
  ];

export default {
  name: 'project-list-other-theme',
  components: {
    pageFooter,
  },
  data() {
    return {
      lists: [
        {
          cover: require('@/assets/images/demo4/list/1.png'),
          info: {
            cn: 'Jinshazhou Shopping Center',
            en: '金沙洲购物中心',
          },
          bg: '#99928e',
        },
        {
          cover: require('@/assets/images/demo4/list/2.png'),
          info: {
            cn: 'Nanning Tus Park',
            en: '南宁启迪东盟科技城',
          },
          bg: '#898a8e',
        },
        {
          isPlaceholder: true,
        },
        {
          cover: require('@/assets/images/demo4/list/3.png'),
          info: {
            cn: 'Shenzhen Metro Changzhen Depot Complex',
            en: '深圳地铁长圳车辆段综合楼',
          },
          bg: '#bcb7aa',
        },
        {
          cover: require('@/assets/images/demo4/list/4.png'),
          info: {
            cn: "Anwei Ma'anshan Ski Resort",
            en: '安微马鞍山滑雪馆',
          },
          bg: '#8c8d8e',
        },
        {
          isPlaceholder: true,
        },
        {
          cover: require('@/assets/images/demo4/list/5.png'),
          info: {
            cn: 'Changbai Mountain Forest and Hot Spring Resort',
            en: '长白山森林温泉度假酒店',
          },
          bg: '#898a8e',
        },
        {
          cover: require('@/assets/images/demo4/list/6.png'),
          info: {
            cn: 'Sichuan Jiuzhai Valley Mouni Shengjing Hotel',
            en: '四川九寨沟牟尼圣境度假酒店',
          },
          bg: '#343232',
        },
        {
          isPlaceholder: true,
        },
        {
          cover: require('@/assets/images/demo4/list/7.jpg'),
          info: {
            cn: 'Haihua Island Cruise Terminal Terminal',
            en: '海花岛邮轮码头航站楼',
          },
          bg: '#898a8e',
        },
        {
          cover: require('@/assets/images/demo4/list/8.png'),
          info: {
            cn: 'Guodian Ningxia Solar Energy Office Building',
            en: '国电宁夏太阳能有限公司办公楼',
          },
          bg: '#8c8d8e',
        },
        {
          cover: require('@/assets/images/demo4/list/9.jpg'),
          info: {
            cn: 'Kunming Xishan Horse Street',
            en: '昆明西山马街',
          },
          bg: '#8c8d8e',
        },
        {
          cover: require('@/assets/images/demo4/list/10.jpg'),
          info: {
            cn: 'Guggenheim Museum',
            en: '古根海姆博物馆',
          },
          bg: '#8c8d8e',
        },
        {
          cover: require('@/assets/images/demo4/list/11.jpg'),
          info: {
            cn: 'Meizhou Changtan Tourist Resort',
            en: '梅州长潭旅游度假酒店',
          },
          bg: '#8c8d8e',
        },
        {
          cover: require('@/assets/images/demo4/list/12.jpg'),
          info: {
            cn: 'Luzhou Cultural Center',
            en: '泸州文化中心',
          },
          bg: '#8c8d8e',
        },
        {
          isPlaceholder: true,
        },
      ],
      projects: [],
      page: 1,
      totalPage: 1,
      requesting: false,
      shapeMap: {
        lists: typeFormat,
        small,
        big,
        placeholder,
      },
    };
  },
  computed: {
    ...mapState(['$bowserMobile']),
    parentid() {
      return Number(this.$route.params.parentid);
    },
    typeid() {
      return Number(this.$route.params.typeid || 0);
    },
    disabled() {
      return this.requesting || this.page > this.totalPage;
    },
    shapeLength() {
      return typeFormat.length;
    },
    currentChildTypes() {
      const current = this.$store.getters.getCurrentTypes(this.parentid);

      if (current) {
        current.unshift({
          id: 0,
          name: {
            en: 'all',
            cn: '全部',
          },
          parent_id: this.parentid,
        });
        return current;
      }
      return [];
    },
  },
  watch: {
    $route: {
      handler() {
        this.projects = [];
        this.page = 1;
        this.totalPage = 1;
        this.requesting = false;
        this.getLists();
      },
      immediate: true,
    },
  },
  methods: {
    getLists() {
      const that = this;
      const parentid = Number(that.$route.params.parentid);
      const typeid = that.$route.params.typeid ? Number(that.$route.params.typeid) : 0;
      if (!that.requesting && that.page <= that.totalPage) {
        that.requesting = true;
        that.$fly
          .get('/api/product/lists', {
            parentid,
            typeid,
            page: this.page,
          })
          .then((res) => {
            const lists = res.data.list;
            const meta = res.data._meta;
            that.page += 1;
            that.totalPage = meta.pageCount;

            const division = that.$bowserMobile ? 2 : 3;

            if (lists.length > 0) {
              for (let i = 0; i < lists.length; i += division) {
                that.projects.push(lists.slice(i, i + division));
              }
            }

            that.$nextTick(() => {
              demo2OnScroll();
            });
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    selectIndex(ph, ci) {
      if (ph >= 0) {
        return ci < ph ? ci : ci - 1;
      }
      return ci;
    },
    selectCover(sp) {
      console.log(sp);
      return 0;
    },
    randomBg() {
      const colors = ['#90998e', '#8b9996', '#958b99', '#998b8d', '#99988b'];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    onJumpDetail(id) {
      this.$router.push({
        path: `/project/${id}?parentid=${this.parentid}`,
      });
    },
  },
};
</script>

<style>
body {
  background-color: #fff;
}
.nmb {
  text-transform: uppercase;
}
</style>
