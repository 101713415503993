<template>
  <div class="v4-page-project-list-container v4-page-project-container-use">
    <template v-if="$bowserMobile && currentChildTypes.length > 0">
      <div class="projects-category-container">
        <div class="category__inner">
          <router-link
            class="category__link"
            :class="{
              actived: typeid === link.id
            }"
            v-for="(link, index) in currentChildTypes"
            :key="index"
            :to="{
              path: `/projects/${$route.params.typeName}/${link.parent_id}/${link.id}`
            }"
          >
            <p class="en">
              {{ link.name.en }}
              <template v-if="link.id === typeid"> | {{ link.name.cn }} </template>
            </p>
            <p class="cn">{{ link.name.cn }}</p>
          </router-link>
        </div>
      </div>
    </template>
    <div class="project-list-box" :class="[onList ? 'show' : '']" ref="projectListBox">
      <div
        class="project-list-link-item theme-2"
        v-for="(project, index) in projects"
        :key="index"
        ref="projectItems"
        :data-id="project.id"
        :data-parentid="parentid"
        :class="[project.visible ? 'visible' : '', project.hide ? 'hide' : '']"
        @touchstart="onTouchStart"
        @touchend="onTouchEnd"
        @click="onClick"
      >
        <a href="javascript:void(0);" class="item__link">
          <div class="text">
            <p class="scrollObj">
              {{ project.name.en }} <span>({{ project.slides.length }})</span>
            </p>
            <div class="hover__title">
              <div class="title__text" :data-text="project.name.en">
                {{ project.name.en }}
              </div>
            </div>
          </div>
          <div class="queue-text">
            <template v-if="$bowserMobile">
              {{ parseNumber(index + 1) }} <span>| {{ project.name.cn }}</span>
            </template>
            <template v-else>
              {{ parseNumber(index + 1) }}
            </template>
          </div>
          <div class="project-cn-name" v-if="!$bowserMobile">
            <div class="project-logo" v-if="project.logo">
              <img :src="project.logo" alt="" />
            </div>
            <p>{{ project.name.cn }}</p>
          </div>
        </a>
        <div class="image">
          <div class="image__inner">
            <img :src="project.cover" alt="" />
          </div>
        </div>
      </div>
    </div>
    <router-link tag="div" class="fixed-back-container" to="/type">
      <div class="dot-container">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </div>
      <div class="text-container">back</div>
    </router-link>
  </div>
</template>

<script>
import { demo2OnScroll } from '@/assets/utils/common';
import { mapState } from 'vuex';

let defaultBg;

export default {
  name: 'site-latest-project-list',
  data() {
    return {
      projects: [],
      visible: !1,
      time: 0,
      velocityTg: 0,
      velocity: 0,
      offset: 0,
      friction: 0.93,
      touched: !1,
      pos: 0,
      projectsPan: 0,
      rotation: 0,
      rotationVel: 0,
      activeImages: new Map(),
      activeListitems: new Map(),
      scrollHeight: 0,
      mouse: {
        x: 0,
        y: 0,
      },
      onList: !1,
      //
      itemsRange: new Map(),
      itemsDom: new Map(),
      activeProjectIndex: -1,
      prevProjectIndex: -1,
      page: 1,
      requesting: false,
      totalPage: 1,
      onTouchTime: 0,
      onTouchLimit: 100,
    };
  },
  computed: {
    ...mapState(['$bowserMobile']),
    parentid() {
      return Number(this.$route.params.parentid);
    },
    typeid() {
      return Number(this.$route.params.typeid || 0);
    },
    mousePos() {
      return {
        x: this.mouse.x,
        y: this.mouse.y + this.scrollHeight,
        yy: this.mouse.y,
      };
    },
    disabled() {
      return this.requesting || this.page > this.totalPage;
    },
    currentChildTypes() {
      const current = this.$store.getters.getCurrentTypes(this.parentid);
      if (current) {
        current.unshift({
          id: 0,
          name: {
            en: 'all',
            cn: '全部',
          },
          parent_id: this.parentid,
        });
        return current;
      }
      return [];
    },
  },
  watch: {
    $route: {
      handler() {
        this.projects = [];
        this.page = 1;
        this.totalPage = 1;
        this.itemsRange = new Map();
        this.itemsDom = new Map();
        this.requesting = false;
        this.getLists();
      },
      immediate: true,
    },
    mousePos: {
      handler(val) {
        const that = this;
        if (that.onList) {
          const values = [...that.itemsRange.values()];
          console.log(values['0']);
          console.log(that.scrollHeight);
          const index = values.find(
            // eslint-disable-next-line max-len
            (item) => val.x >= item.left && val.x <= item.right && val.y >= item.top && val.y <= item.bottom,
          );
          if (index) {
            const { id } = index;
            that.activeProjectIndex = id;
            const { image, wrapper } = that.itemsDom.get(id);
            const imageInner = image.querySelector('.image__inner');
            const { width, height } = imageInner.getBoundingClientRect();
            if (!wrapper.classList.contains('fullsize')) {
              image.style.cssText = `transform: translate(${val.x
                - Math.floor(width / 2)}px,${val.yy - Math.floor(height / 2)}px);`;
            }
            document.body.classList.add('logo-change');
          } else {
            that.activeProjectIndex = -1;
            document.body.classList.remove('logo-change');
          }
        } else {
          that.activeProjectIndex = -1;
          document.body.classList.remove('logo-change');
        }
      },
      deep: true,
    },
    activeProjectIndex(val) {
      const that = this;
      let tempA;
      if (val >= 0) {
        let bg;
        tempA = that.projects.map((item) => {
          const temp = item;
          temp.visible = Number(item.id) === Number(val);
          temp.hide = Number(item.id) !== Number(val);
          if (Number(item.id) === Number(val)) {
            bg = item.bg;
          }
          return temp;
        });
        that.$store.commit('setBackgroundColor', bg);
      } else {
        tempA = that.projects.map((item) => {
          const temp = item;
          temp.visible = false;
          temp.hide = false;
          return temp;
        });
        that.$store.commit('setBackgroundColor', defaultBg);
      }
      that.projects = tempA;
    },
  },
  mounted() {
    const that = this;
    window.$('body').css({
      backgroundColor: 'transparent',
    });
    that.$nextTick(() => {
      if (that.$bowserMobile) {
        console.log('on mobile start');
      } else {
        that.$refs.projectListBox.addEventListener('mouseenter', () => {
          that.onList = true;
        });
        that.$refs.projectListBox.addEventListener('mouseleave', () => {
          that.onList = false;
        });
      }
    });
  },
  destroyed() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    document.documentElement.classList.remove('logo-change');
    document.documentElement.style.cssText = 'background-color:#fff';
    // document.querySelector('.project-bg').style.cssText = 'background-color:#fff';
    this.$store.commit('setBackgroundColor', defaultBg);
  },
  beforeRouteLeave(t, e, o) {
    document.body.classList.remove('logo-change');
    document.documentElement.style.cssText = 'background-color:#fff';
    this.$store.commit('setBackgroundColor', defaultBg);
    this.visible = !1;
    setTimeout(() => {
      o();
    }, 500);
  },
  methods: {
    handleMouseMove(t) {
      const e = t.clientX;
      this.velocityTg = e - this.pos;
      this.pos = e;
      this.mouse.x = e;
      this.mouse.y = t.clientY;
    },
    handlScroll() {
      this.scrollHeight = document.documentElement.scrollTop || document.body.scrollTop;
      // this.getDomPosData();
    },
    parseNumber(nub) {
      if (nub < 10) {
        return `00${nub}`;
      }
      if (nub >= 10 && nub < 100) {
        return `0${nub}`;
      }
      return nub;
    },
    pageInit() {
      console.log(this.$route.params);
    },
    getLists() {
      const that = this;
      const parentid = Number(that.$route.params.parentid);
      const typeid = that.$route.params.typeid ? Number(that.$route.params.typeid) : 0;
      if (!that.requesting && that.page <= that.totalPage) {
        that.requesting = true;
        that.$fly
          .get('/api/product/lists', {
            parentid,
            typeid,
            page: this.page,
          })
          .then((res) => {
            const lists = res.data.list;
            const meta = res.data._meta;
            that.page += 1;
            that.totalPage = meta.pageCount;
            that.projects.push(...lists);
            that.refreshEffect();
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    refreshEffect() {
      const that = this;
      that.$nextTick(() => {
        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('scroll', this.handlScroll);
        demo2OnScroll();
        // defaultBg = window.$('html').css('backgroundColor');
        defaultBg = '#f6f6f6';
        // 初始化 project range
        that.getDomPosData();
      });
    },
    getDomPosData() {
      const that = this;
      const items = Array.from(that.$refs.projectItems);
      items.forEach((item) => {
        const bcr = item.getBoundingClientRect();
        const { id } = item.dataset;
        that.itemsRange.set(id, {
          id,
          top: bcr.top,
          bottom: bcr.bottom,
          left: bcr.left,
          right: bcr.right,
          width: bcr.width,
          textWidth: item.querySelector('.title__text').getBoundingClientRect().width,
        });
        if (item.querySelector('.title__text').getBoundingClientRect().width >= bcr.width) {
          item.querySelector('.title__text').classList.add('hoverE');
        }
        that.itemsDom.set(id, {
          wrapper: item,
          image: item.querySelector('.image'),
        });
      });
    },
    onTouchStart(e) {
      const obj = e.currentTarget;
      const { id } = obj.dataset;
      const parent = obj.parentNode;
      parent.classList.add('show');
      let bg;
      this.onTouchTime = Date.now();
      console.log(this.onTouchTime);
      this.projects = this.projects.map((item) => {
        const temp = item;
        temp.visible = Number(temp.id) === Number(id);
        temp.hide = !Number(temp.id) === Number(id);
        if (Number(temp.id) === Number(id)) {
          bg = item.bg;
        }
        return temp;
      });
      this.$store.commit('setBackgroundColor', bg);
    },
    onTouchEnd(e) {
      const obj = e.currentTarget;
      const parent = obj.parentNode;
      const current = Date.now();
      parent.classList.remove('show');
      this.projects = this.projects.map((item) => {
        const temp = item;
        temp.visible = false;
        temp.hide = false;
        return temp;
      });
      this.$store.commit('setBackgroundColor', defaultBg);
      if (current - this.onTouchTime <= this.onTouchLimit) {
        this.$router.push({
          path: `/project/${obj.dataset.id}?parentid=${obj.dataset.parentid}`,
        });
      }
    },
    onClick(e) {
      if (!this.$bowserMobile) {
        const obj = e.currentTarget;
        this.$router.push({
          path: `/project/${obj.dataset.id}?parentid=${obj.dataset.parentid}`,
        });
      }
    },
  },
};
</script>

<style>
body {
  background-color: "#f6f6f6";
}
</style>
