var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v4-page-list-2-container v4-page-project-container-use"},[_c('div',{staticClass:"list2-box-container"},[(_vm.$bowserMobile && _vm.currentChildTypes.length > 0)?[_c('div',{staticClass:"projects-category-container"},[_c('div',{staticClass:"category__inner"},_vm._l((_vm.currentChildTypes),function(link,index){return _c('router-link',{key:index,staticClass:"category__link",class:{
              actived: _vm.typeid === link.id
            },attrs:{"to":{
              path: ("/projects/" + (_vm.$route.params.typeName) + "/" + (link.parent_id) + "/" + (link.id))
            }}},[_c('p',{staticClass:"en"},[_vm._v(" "+_vm._s(link.name.en)+" "),(link.id === _vm.typeid)?[_vm._v(" | "+_vm._s(link.name.cn)+" ")]:_vm._e()],2),_c('p',{staticClass:"cn"},[_vm._v(_vm._s(link.name.cn))])])}),1)])]:_vm._e(),(_vm.projects.length > 0)?_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.getLists),expression:"getLists"}],staticClass:"box__wrapper",attrs:{"infinite-scroll-disabled":_vm.disabled,"infinite-scroll-immediate":false}},_vm._l((_vm.projects),function(row,index){return _c('div',{key:index,staticClass:"list-box__item-row"},_vm._l((_vm.shapeMap.lists[index % _vm.shapeLength]),function(shape,si){return _c('div',{key:si,staticClass:"list-box__item scrollObj",class:[shape === _vm.shapeMap.small ? 'mobile-small' : 'mobile-big']},[(
              shape !== _vm.shapeMap.placeholder &&
                row[
                  _vm.selectIndex(
                    _vm.shapeMap.lists[index % _vm.shapeLength].findIndex(
                      function (item) { return item === _vm.shapeMap.placeholder; }
                    ),
                    si
                  )
                ]
            )?[_c('div',{staticClass:"item__wrapper",on:{"click":function($event){_vm.onJumpDetail(
                  row[
                    _vm.selectIndex(
                      _vm.shapeMap.lists[index % _vm.shapeLength].findIndex(
                        function (item) { return item === _vm.shapeMap.placeholder; }
                      ),
                      si
                    )
                  ].id
                )}}},[_c('div',{staticClass:"item__cover",style:({
                  backgroundColor: _vm.randomBg()
                })},[_c('img',{staticClass:"lazyload",attrs:{"data-shape":shape === _vm.shapeMap.small ? 'sm' : 'big',"data-src":row[
                      _vm.selectIndex(
                        _vm.shapeMap.lists[index % _vm.shapeLength].findIndex(
                          function (item) { return item === _vm.shapeMap.placeholder; }
                        ),
                        si
                      )
                    ].cover,"alt":""}})]),_c('div',{staticClass:"item__info"},[_c('p',[_vm._v(" "+_vm._s(row[ _vm.selectIndex( _vm.shapeMap.lists[index % _vm.shapeLength].findIndex( function (item) { return item === _vm.shapeMap.placeholder; } ), si ) ].name.en)+" ")]),_c('p',[_vm._v(" "+_vm._s(row[ _vm.selectIndex( _vm.shapeMap.lists[index % _vm.shapeLength].findIndex( function (item) { return item === _vm.shapeMap.placeholder; } ), si ) ].name.cn)+" ")])])])]:_vm._e()],2)}),0)}),0):_vm._e()],2),_c('div',{staticClass:"list-2-fixed-title scrollObj",attrs:{"data-extend":"delay"}},[_c('p',{staticClass:"nmb"},[_vm._v(_vm._s(_vm.$store.state.currentSubType))])]),_c('page-footer'),_c('router-link',{staticClass:"fixed-back-container",attrs:{"tag":"div","to":"/type"}},[_c('div',{staticClass:"dot-container"},[_c('i'),_c('i'),_c('i'),_c('i')]),_c('div',{staticClass:"text-container"},[_vm._v("back")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }