<template>
  <shape-list v-if="shape === 'shape-1'"></shape-list>
  <shape-grid v-else-if="shape === 'shape-2'"></shape-grid>
</template>

<script>
import shapeList from './list-theme-2.vue';
import shapeGrid from './list-2.vue';

export default {
  name: 'projects-list-shape',
  components: {
    shapeList,
    shapeGrid,
  },
  computed: {
    shape() {
      return this.$route.params.typeName === '室内设计'
        || this.$route.params.typeName === 'interior'
        ? 'shape-1'
        : 'shape-2';
    },
  },
};
</script>

<style></style>
